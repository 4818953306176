export default {
    locale: 'fr',
    errors: {
        genericTryAgain:
            "Merci d'essayer de nouveau un peu plus tard ou de communiquer avec l'administrateur " +
            "du site.",
        getPostings:
            "Une erreur est survenue en tentant de récupérer la liste de postes publiés.",
        getPostingDetails:
            "Une erreur est survenue en tentant de récupérer les informations du poste " +
            "sélectionné. Le service peut être présentement indisponible ou le poste peut ne plus être existant.",
        getPostingsFilters:
            "Une erreur est survenue en tentant de charger les filtres de postes.",
        getSelfServiceUrl:
            "Une erreur est survenue en tentant de récupérer l'URL du libre-service.",
        getValidationParameters:
            "Une erreur est survenue en tentant de récupérer les paramètres de validation.",
        applyToPosting:
            "Une erreur est survenue en tentant de poser la candidature.",
        addAttachment:
            "Une erreur est survenue en tentant d'ajouter un fichier en pièce jointe.",
        addExtraAttachment:
            "Une erreur est survenue en tentant d'ajouter le fichier «{{fileName}}». " +
            "Votre candidature a tout de même été enregistrée avec succès."
    },
    tableHeaders: {
        title: "Titre",
        institution: "Catégorie",
        location: "Lieu",
        deadline: "Date limite",
        commitment: "Type d'engagement",
        sector: "Secteur d'emploi",
        jobField: "Domaine d'emploi",
        jobClass: "Classe d'emploi",
        competitionNumber: "No. de concours"
    },
    labels: {
        firstName: "Prénom",
        lastName: "Nom",
        email: "Courriel",
        phone: "Numéro de téléphone",
        password: "Mot de passe",
        passwordConfirmation: "Confirmation du mot de passe",
        resume: "Téléverser mon CV",
        comment: "Commentaire",
        other: "Autre",
        institution: "Catégorie",
        jobSector: "Secteur d'emploi",
        jobField: "Domaine d'emploi",
        jobClass: "Classe d'emploi",
        commitment: "Type d'engagement",
        location: "Lieu de travail",
        spontaneousApplicationEntry: "Candidature spontanée",
        politiqueConfidentialite: "Je déclare que j'ai lu et que j'accepte <a class=\"manitouNormalLink\" href=\"${policyURL}\" target=\"_blank\">la politique de confidentialité de Manitou et les conditions d'utilisation</a> de la solution. *",
        infoLettre: "Je désire recevoir des infolettres par courriel.",
        approbationSMS: "J'accepte qu'on communique avec moi par texto.",
        declarationAge: "Je déclare avoir plus de 14 ans. *"
    },
    buttons: {
        sendApplication: "Postuler",
        apply: "Postuler",
        file: "Fichier",
        filterResults: "Filtrer les résultats",
        applyFilters: "Appliquer les filtres",
        resetFilters: "Réinitialiser les filtres",
        backToPostings: "Retour à la liste des postes",
        facebookTooltip: "Partager sur Facebook",
        twitterTooltip: "Partager sur Twitter",
        linkedinTooltip: "Partager sur LinkedIn",
        mailTooltip: "Partager par courriel",
        previousTooltip: "Page précédente",
        nextTooltip: "Page suivante",
        selfService: "Accéder au libre-service",
        iTryMyLuck: "Je me lance"
    },
    validations: {
        emailTooltip:
            "Une adresse courriel valide doit contenir un '@' et se terminer par un nom de domaine " +
            "valide (exemple@exemple.com).",
        emailError:
            "Veuillez entrer une adresse courriel valide (exemple@exemple.com).",
        emailCorrect: "Cette adresse courriel est valide!",
        firstNameError: "Le champ Prénom est obligatoire.",
        lastNameError: "Le champ Nom est obligatoire.",
        attachmentError: "Ce fichier est obligatoire.",
        requiredFieldError: "Ce champ est obligatoire.",
        attachmentMaxSizeError:
            "Le fichier doit être plus petit que {{maxsize}} octets.",
        attachmentEmptyError: "Le contenu du fichier ne peut pas être vide.",
        attachmentNameEmptyError: "Le nom du fichier ne peut pas être vide.",
        privacyPolicyError: "Vous devez acceptez la politique de confidentialité et les conditions d'utilisation pour postuler.",
        declarationAgeError: "Vous devez déclarer avoir plus de 14 ans pour postuler."
    },
    titles: {
        postingsListTitle: "Postes actuellement disponibles",
        applicationForm: "Postuler pour ce poste",
        spontaneousApplication: "Candidature spontanée"
    },
    text: {
        selfService:
            "Un libre-service est disponible pour déposer votre curriculum vitae, compléter votre profil," +
            " déposer une candidature spontanée, recevoir des alertes quand un poste correspondant à vos " +
            "compétences est disponible et encore plus!",
        spontaneousApplication:
            "Vous ne trouvez pas l'emploi que vous cherchez? Faites-nous parvenir votre candidature!"
    },
    defaultOptions: {
        institution: "- Tous les établissements -",
        jobSector: "- Tous les secteurs -",
        jobField: "- Tous les domaines d'emploi -",
        jobClass: "- Toutes les classes d'emploi -",
        commitment: "- Tous les types d'engagement -",
        location: "- Tous les lieux de travail -",
        other: "Autre"
    },
    pagination: {
        countResults: "Résultat(s)",
        countTo: "à",
        countOf: "de"
    },
    email: {
        shareSubject: "Emploi offert"
    },
    noDataFound: {
        noPostings: "Aucun poste n'est présentement publié.",
        noSearchResults:
            "Aucun poste ne correspond présentement à vos critères."
    },
    services: {
        platform: "Plateforme emploi"
    }
};
