
require('normalize.css/normalize.css');
require('./styles/index.scss');

window.jQuery = require('jquery');
window.$ = require('jquery');

require('materialize-css');

import * as manitou from './js/app.js'

// Merge or create the manitou global object
if (window.manitou) {
    for (var key in manitou) {
        // Avoid bugs when hasOwnProperty is shadowed
        if (Object.prototype.hasOwnProperty.call(manitou, key)) {
            window.manitou[key] = manitou[key];
        }
    }
} else {
    window.manitou = manitou;
}

// Handle Back/Forward inside the platform
window.addEventListener('popstate', function (event) {
    manitou.init(window.history.state.manLanguage);
}, false);
