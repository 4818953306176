import messagesFR from './fr.js'
import messagesEN from './en.js'

var messages = messagesFR;

function initMessages(language) {
    messages = (language.toLowerCase() == 'fr' ? messagesFR : messagesEN);
};

export { messages as default, initMessages }
