import messages from './lang/messages.js'

export {
    postingsListPage,
    spontaneousApplicationPage,
    postingDetailsPage,
    selfServiceLink,
    errorMessage,
    inlineError,
    successMessage,
    attachmentErrorMessage,
    preloader
}

var privateTemplates = {};

function postingsListPage (postings, filters, isFiltered, columns, postingsGroups) {
    var noResultFound = postings.length == 0;
    var postingsGroupsHtml = '';
    if (manitou.config.enablePostingsGrouping && postingsGroups) {
        postingsGroups.forEach(
            function(postingsGroup) {
                postingsGroupsHtml += privateTemplates.postingsGroup(postingsGroup.postings, isFiltered, columns, postingsGroup.title, postingsGroup.id);
            }
        );
    } else {
        postingsGroupsHtml = privateTemplates.postingsGroup(postings, isFiltered, columns);
    }

    var html =
        '<div id="manitouPostingsContainer" class="row">' +
            (!manitou.config.hideTitle ?
                '<h3 class="manitouTitle">' +
                    ((manitou.config.title && manitou.config.title[messages.locale]) || messages.titles.postingsListTitle) +
                '</h3>':
                ''
            ) +
            '<div id="manitouSelfServiceContainer" class="col s12"></div>' +
            ( !manitou.config.hideFilters && (!noResultFound || isFiltered) ? // Hides the filters if there is no postings to filter
                '<div class="col s12">' +
                privateTemplates.postingsFilters(filters, isFiltered) +
                '</div>' :
                '') +
            postingsGroupsHtml +
        '</div>';
    return html;
};

privateTemplates.postingsGroup = function (postings, isFiltered, columns, title, id) {
    var noResultFound = postings.length == 0;
    //Hide pagination when there is only one page of postings or there is no results
    var displayPagination = !noResultFound && !manitou.config.disablePagination && (postings && postings.length > manitou.config.itemsPerPage);
    var html =
        '<div class="col s12 manitouPostingsGroupContainer" ' + (id ? 'id="' + id + '"': '') + '>' +
            (title ? '<h4 class="manitouSubTitle">' + title + '</h4>': '') +
            (displayPagination && !manitou.config.hideTopPagination ? privateTemplates.paginationBlock() : '') +
            '<div class="col s12">' +
                (noResultFound && !manitou.config.displaySpontaneousApplicationListEntry ?
                    privateTemplates.noPostingsFound(isFiltered) :
                    privateTemplates.postingsTable(postings, columns)) +
            '</div>' +
            (displayPagination && !manitou.config.hideBottomPagination ? privateTemplates.paginationBlock() : '') +
        '</div>';
    return html;
};

privateTemplates.paginationBlock = function () {
    var html =
        '<div class="col s12 center-align manitouPaginationContainer">' +
            '<div>' +
                messages.pagination.countResults + ' ' +
                '<span class="manitouLowerResult"></span> ' +
                messages.pagination.countTo + ' ' +
                '<span class="manitouUpperResult"></span> ' +
                messages.pagination.countOf + ' ' +
                '<span class="manitouTotalResults"></span>' +
            '</div>' +
            '<ul class="pagination"></ul>' +
        '</div>';
    return html;
};

/*  The extraAttachments is an array of objects following this model:
        {label: 'label', documentTypeId: 2, isRequired: true}
    The customSelectionLists is an array of objects following this model:
        {
           label: 'field label',
            isRequired: true,
            multipleSelections: false,
            otherManualOption: false,
            options: [
                {
                    label: 'label',
                    value: value
                }
            ]
        } */
function spontaneousApplicationPage (extraAttachments, resumeAttachmentId, displayComment, customSelectionLists, displaySMSCheckbox, privacyPolicyURL) {
    var html =
        '<div id="manitouSpontaneousApplicationContainer" class="row">' +
            '<div class="col s12">' +
                privateTemplates.applicationForm('', messages.titles.spontaneousApplication,
                    messages.text.spontaneousApplication, extraAttachments, resumeAttachmentId, displayComment, customSelectionLists, displaySMSCheckbox, privacyPolicyURL) +
            '</div>' +
        '</div>';
    return html;
};

/*  The extraAttachments is an array of objects following this model:
        {label: 'label', documentTypeId: 2, isRequired: true}
    The customSelectionLists is an array of objects following this model:
        {
           label: 'field label',
            isRequired: true,
            multipleSelections: false,
            otherManualOption: false,
            options: [
                {
                    label: 'label',
                    value: value
                }
            ]
        } */
function postingDetailsPage (postingDetails, extraAttachments, resumeAttachmentId, displayComment, customSelectionLists, displaySMSCheckbox, privacyPolicyURL) {
    var html =
        '<div id="manitouPostingDetailsContainer" class="row">' +
            privateTemplates.backToPostingsButton() +
            '<div class="col s12 xl7">' +
                privateTemplates.postingDetails(postingDetails) +
            '</div>' +
            '<div class="col s12 xl5">' +
                privateTemplates.applicationForm(postingDetails.id, messages.titles.applicationForm, null,
                    extraAttachments, resumeAttachmentId, displayComment, customSelectionLists, displaySMSCheckbox, privacyPolicyURL) +
            '</div>' +
            privateTemplates.backToPostingsButton() +
        '</div>';
    // Add the google for job script tag
    if (postingDetails.googleForJob) {
        html += postingDetails.googleForJob;
    }

    return html;
};

function selfServiceLink (url) {
    var html =

        '<h6>' +
            messages.text.selfService +
        '</h6>' +
        '<a class="waves-effect waves-light btn" href="' + url + '" target="_blank">' +
            messages.buttons.selfService +
        '</a>';
    return html;
};

function errorMessage(errorMessage) {
    var html =
        '<div class="row manitouErrorContainer center-align">' +
            '<div class="col s12">' +
                '<i class="material-icons medium">error_outline</i>' +
            '</div>' +
            '<div class="col s12">' +
                '<h5 class="flow-text">' +
                    errorMessage +
                '</h5>' +
                '<h5 class="flow-text">' +
                    messages.errors.genericTryAgain +
                '</h5>' +
            '</div>' +
        '</div>';
        return html;
};

function inlineError (errorMessage) {
    var html =
        '<div>' + errorMessage + '</div>';
    return html;
};

function successMessage (successMessage) {
    var html =
    '<div class="col s12 manitouSuccessMessage">' +
        '<h5>' + successMessage + '</h5>' +
    '</div>';
    return html;
};

function attachmentErrorMessage (fileName) {
    var html =
    '<div class="col s12 manitouErrorMessage">' +
        '<h5>' + messages.errors.addExtraAttachment.replace('{{fileName}}', (fileName ? fileName : '')) + '</h5>' +
    '</div>';
    return html;
};

function preloader () {
    var html =
        '<div class="row center-align manitouPreloaderContainer">' +
            '<div class="preloader-wrapper big active center-align">' +
                '<div class="spinner-layer spinner-blue-only">' +
                '<div class="circle-clipper left">' +
                    '<div class="circle"></div>' +
                '</div><div class="gap-patch">' +
                    '<div class="circle"></div>' +
                '</div><div class="circle-clipper right">' +
                    '<div class="circle"></div>' +
                '</div>' +
                '</div>' +
            '</div>' +
        '</div>';
    return html;
};

privateTemplates.postingsFilters = function (filters, areFiltersActive) {
    var filtersHtml = '';
    $.each(filters, function (filterType, filtersList) {
        var filterOptionsHtml = '';

        $.each(filtersList, function (index, filter) {
            filterOptionsHtml +=
                '<option value="' + filter.id + '">' + filter.value + '</option>';
        });

        filtersHtml +=
            '<div class="input-field col s12 m6 l4">' +
                '<select data-id="manitouFilter_' + filterType + '" class="manitouFilter" data-filter-type="' + filterType + '">' +
                    '<option value="0" selected>' + messages.defaultOptions[filterType] + '</option>' +
                    filterOptionsHtml +
                '</select>' +
                '<label for="manitouFilter_' + filterType + '">' + messages.labels[filterType] + '</label>' +
            '</div>';
    });

    var html =
        '<ul class="collapsible">' +
            '<li ' + (areFiltersActive ? 'class="active"' : '') + '>' +
                '<div class="collapsible-header">' +
                    '<i class="material-icons">filter_list</i>' +
                    messages.buttons.filterResults +
                '</div>' +
                '<div class="collapsible-body">' +
                    '<div class="row">' +
                        filtersHtml +
                    '</div>' +
                    '<div class="row">' +
                        '<div class="col s12">' +
                            '<a class="waves-effect waves-light btn manitouApplyFiltersButton">' +
                                messages.buttons.applyFilters +
                            '</a>' +
                        '</div>' +
                        '<div class="col s12">' +
                            '<a class="waves-effect manitouResetFiltersButton manitouLink">' +
                                messages.buttons.resetFilters +
                            '</a>' +
                        '</div>' +
                    '</div>' +
                '</div>' +
            '</li>' +
        '</ul>';
    return html;
};

privateTemplates.postingsTable = function (postings, columns) {
    var postingsHtml = '';
    $.each(postings, function (index, posting) {
        postingsHtml +=
            '<tr>' +
                columns.reduce(
                    function(columnsHtml, columnName) {
                        return columnsHtml + '<td>' + (posting[columnName] ? posting[columnName] : '') + '</td>';
                    }, ''
                ) +
                '<td class="manitouButtonColumnCell">' +
                    '<a class="waves-effect waves-light btn manitouPostingButton" data-posting-id="' + posting.id + '">' +
                        (messages.locale && manitou.config.applyButtonText && manitou.config.applyButtonText[messages.locale] ?
                            manitou.config.applyButtonText[messages.locale] :
                            messages.buttons.apply) +
                    '</a>' +
                '</td>' +
            '</tr>';
    });
    if (manitou.config.displaySpontaneousApplicationListEntry) {
        postingsHtml +=
            '<tr>' +
                columns.reduce(
                    function(columnsHtml, columnName) {
                        return columnsHtml + '<td>' + (columnName == 'title' ? messages.labels.spontaneousApplicationEntry : '') + '</td>';
                    }, ''
                ) +
                '<td class="manitouButtonColumnCell">' +
                    '<a class="waves-effect waves-light btn manitouSpontaneousApplicationButton">' +
                        messages.buttons.iTryMyLuck +
                    '</a>' +
                '</td>' +
            '</tr>';

    }
    var html =
        '<div class="row manitouPostingsTable">' +
            '<div class="col s12 manitouPostingTableContainer">' +
                '<table class="highlight">' +
                    '<thead>' +
                        '<tr>' +
                            columns.reduce(
                                function(headersHtml, columnName) {
                                    return headersHtml + '<th>' + messages.tableHeaders[columnName] + '</th>';
                                }, ''
                            ) +
                            '<td></td>' +
                        '</tr>' +
                    '</thead>' +
                    '<tbody>' +
                        postingsHtml +
                    '</tbody>' +
                '</table>' +
            '</div>' +
        '</div>';
    return html;
};

privateTemplates.postingDetails = function (postingDetails) {
    var contentHeaderHtml = '';
    var contentBodyHtml = '';
    var contentFooterHtml = '';

    $.each(postingDetails.contentHeader, function (index, content) {
        contentHeaderHtml +=
            '<div class="row">' +
                (content.label ?
                    '<div class="col s12 m3 manitouLabel">' +
                        content.label +
                    '</div>'
                    : ''
                ) +
                '<div class="col s12 m9">' +
                    content.value +
                '</div>' +
            '</div>';
    });

    $.each(postingDetails.contentBody, function (index, content) {
        contentBodyHtml +=
            '<div class="row">' +
                (content.label ?
                    '<div class="col s12 m3">' +
                        '<div class="row manitouLabelRow">' +
                            '<div class="col m10 valign-wrapper">' +
                                '<span class="manitouLabel">' + content.label + '</span>' +
                            '</div>' +
                        '</div>' +
                    '</div>'
                    : ''
                ) +
                '<div class="col s12 m9">' +
                    content.value +
                '</div>' +
            '</div>';
    });

    $.each(postingDetails.contentFooter, function (index, content) {
        contentFooterHtml +=
            '<div class="row">' +
                (content.label ?
                    '<div class="col s12 m3 manitouLabel">' +
                        content.label +
                    '</div>'
                    : ''
                ) +
                '<div class="col s12 m9">' +
                    content.value +
                '</div>' +
            '</div>';
    });

    var html =
        '<div id="manitouPostingDetails" class="row">' +
            '<div class="col s12">' +
                '<div class="row">' +
                    '<div class="col s12">' +
                        '<h3>' + postingDetails.title + '</h3>' +
                    '</div>' +
                    '<div class="divider col s12"></div>' +
                    '<div class="col s12 valign-wrapper">' +
                        '<img id="manitouLogo" alt="manitouLogo" class="responsive-img" src="' + postingDetails.logoURL + '"></img>' +
                        '<div id="manitouSocialsContainer">' +
                            '<a href="' + postingDetails.facebookURL + '" class="waves-effect waves-dark btn-flat" ' +
                                'title="' + messages.buttons.facebookTooltip + '" target="_blank">' +
                                '<i class="fab fa-fw fa-facebook-f"></i>' +
                            '</a>' +
                            '<a href="' + postingDetails.twitterURL + '" class="waves-effect waves-dark btn-flat" ' +
                                'title="' + messages.buttons.twitterTooltip + '" target="_blank">' +
                                '<i class="fab fa-fw fa-twitter"></i>' +
                            '</a>' +
                            '<a href="' + postingDetails.linkedInURL + '" class="waves-effect waves-dark btn-flat" ' +
                                'title="' + messages.buttons.linkedinTooltip + '" target="_blank">' +
                                '<i class="fab fa-fw fa-linkedin-in"></i>' +
                            '</a>' +
                            '<a class="waves-effect waves-dark btn-flat" href="mailto:?subject=' +
                                messages.email.shareSubject + '&body=<' + postingDetails.sharingURL + '>"' +
                                'title="' + messages.buttons.mailTooltip + '">' +
                                '<i class="fas fa-fw fa-envelope"></i>' +
                            '</a>' +
                        '</div>' +
                    '</div>' +
                    '<div class="divider col s12"></div>' +

                '</div>' +
            '</div>' +
            '<div class="col s12">' +
                (contentHeaderHtml ?
                    '<div id="manitouContentHeader" class="row">' +
                        '<div class="col s12">' +
                            contentHeaderHtml +
                        '</div>' +
                    '</div>' +
                    '<div class="divider"></div>'
                    : ''
                ) +
                (contentBodyHtml ?
                    '<div id="manitouContentBody" class="row">' +
                        '<div class="col s12">' +
                            contentBodyHtml +
                        '</div>' +
                    '</div>'
                    : ''
                ) +
                (contentFooterHtml ?
                    '<div class="divider"></div>' +
                    '<div id="manitouContentFooter" class="row">' +
                        '<div class="col s12">' +
                            contentFooterHtml +
                        '</div>' +
                    '</div>'
                    : ''
                ) +
            '</div>' +
        '</div>';

    return html;
};

/*  The extraAttachments is an array of objects following this model:
        {label: 'label', documentTypeId: 2, isRequired: true}
    The customSelectionLists is an array of objects following this model:
        {
           label: 'field label',
            isRequired: true,
            multipleSelections: false,
            otherManualOption: false,
            options: [
                {
                    label: 'label',
                    value: value
                }
            ]
        } */
privateTemplates.applicationForm = function (postingId, title, text, extraAttachments, resumeAttachmentId, displayComment, customSelectionLists, displaySMSCheckbox, privacyPolicyURL) {
    var extraAttachmentsHtml = '';
    $.each(extraAttachments, function (index, attachment) {
        extraAttachmentsHtml += privateTemplates.attachmentField(
            attachment.label, attachment.documentTypeId, attachment.isRequired, 'manitouFile-' + index,
            'manitouFileName-' + index, 'manitouExtraAttachment'
        );
    });

    var customSelectFieldsHtml = '';
    $.each(customSelectionLists, function (index, selectList) {
        customSelectFieldsHtml += privateTemplates.customSelectField(
            selectList.label, selectList.isRequired, selectList.multipleSelections,
            selectList.otherManualOption, selectList.options, 'manitouCustomSelect-' + index,
            selectList.columnWidth, selectList.isConcatenatedInComment, index
        );
    });

    var html =
        '<div id="manitouApplicationForm" class="row card-panel">' +
            '<form class="col s12">' +
                '<div class="row">' +
                    '<div class="col s12 center-align">' +
                        '<h4>' + title + '</h4>' +
                        (text ? '<p>' + text + '</p>': '') +
                    '</div>' +
                '</div>' +
                '<div class="row manitouFormRow">' +
                    privateTemplates.attachmentField(messages.labels.resume, resumeAttachmentId, true, 'manitouResumeFile', 'manitouResumeFileName', 'manitouResume') +
                    extraAttachmentsHtml +
                '</div>' +
                '<div class="row manitouFormRow">' +
                    '<div class="input-field col s6">' +
                        '<input id="manitouFirstName" type="text" class="validate" maxlength="200" required>' +
                        '<label for="manitouFirstName">' + messages.labels.firstName + ' *</label>' +
                        '<span class="helper-text" data-error="' + messages.validations.firstNameError + '"></span>' +
                    '</div>' +
                    '<div class="input-field col s6">' +
                        '<input id="manitouLastName" type="text" class="validate" maxlength="100" required>' +
                        '<label for="manitouLastName">' + messages.labels.lastName + ' *</label>' +
                        '<span class="helper-text" data-error="' + messages.validations.lastNameError + '"></span>' +
                    '</div>' +
                '</div>' +
                '<div class="row manitouFormRow">' +
                    '<div class="input-field col s12">' +
                        '<input id="manitouEmail" type="email" class="validate" maxlength="250" ' +
                            'title="' + messages.validations.emailTooltip + '" required>' +
                        '<label for="manitouEmail">' + messages.labels.email + ' *</label>' +
                        '<span class="helper-text" data-error="' + messages.validations.emailError + '" ' +
                            'data-success="' + messages.validations.emailCorrect + '"></span>' +
                    '</div>' +
                '</div>' +
                '<div class="row manitouFormRow">' +
                    '<div class="input-field col s12">' +
                        '<input id="manitouPhone" type="text" class="validate" maxlength="25">' +
                        '<label for="manitouPhone">' + messages.labels.phone + '</label>' +
                        '<span class="helper-text" data-error="" data-success=""></span>' +
                    '</div>' +
                '</div>' +
                '<div class="row manitouFormRow">' +
                    customSelectFieldsHtml +
                '</div>' +
                (displayComment ? privateTemplates.commentField(false): '') +
                '<div class="row manitouFormRow">' +
                    '<label>' +
                        '<input id="manitouPrivacyPolicy" type="checkbox" class="filled-in validate" required />' +
                        '<span>' +
                            messages.labels.politiqueConfidentialite.replace(
                                '${policyURL}', privacyPolicyURL || 'https://manitousolution.com/politique-de-confidentialite/'
                            ) +
                        '</span>' +
                        '<span class="helper-text" data-error="' + messages.validations.privacyPolicyError + '" data-success=""></span>' +
                    '</label>' +
                '</div>' +
                '<div class="row manitouFormRow">' +
                    '<label>' +
                        '<input id="manitouAgeDeclaration" type="checkbox" class="filled-in validate" required />' +
                        '<span>' + messages.labels.declarationAge + '</span>' +
                        '<span class="helper-text" data-error="' + messages.validations.declarationAgeError + '" data-success=""></span>' +
                    '</label>' +
                '</div>' +
                '<div class="row manitouFormRow" style="display:none;">' +
                    '<label>' +
                        '<input id="manitouMailingList" type="checkbox" class="filled-in" />' +
                        '<span>' + messages.labels.infoLettre + '</span>' +
                        '<span class="helper-text" data-error="" data-success=""></span>' +
                    '</label>' +
                '</div>' +
                (displaySMSCheckbox ?
                    '<div class="row manitouFormRow">' +
                        '<label>' +
                            '<input id="manitouSMSApproval" type="checkbox" class="filled-in" />' +
                            '<span>' + messages.labels.approbationSMS + '</span>' +
                            '<span class="helper-text" data-error="" data-success=""></span>' +
                        '</label>' +
                    '</div>'
                    : ''
                ) +
                '<div class="row">' +
                    '<div id="manitouApplicationFormErrors" class="col s12">' +
                    '</div>' +
                    '<div class="col s12 center-align">' +
                        '<a id="manitouApplyButton" class="waves-effect waves-light btn-large" ' +
                            (postingId ? 'data-posting-id="' + postingId + '"' : '') + '>' +
                            '<i class="material-icons right">send</i>' +
                            messages.buttons.sendApplication +
                        '</a>' +
                    '</div>' +
                '</div>' +
            '</form>' +
        '</div>';
    return html;
};

privateTemplates.noPostingsFound = function(isFiltered) {
    var html =
        '<div class="row manitouNoPostingsFound">' +
            '<div class="col s12">' +
                '<h5>' +
                    (isFiltered ?
                        messages.noDataFound.noSearchResults :
                        messages.noDataFound.noPostings) +
                '</h5>' +
            '</div>' +
            (isFiltered ?
                '<div class="col s12">' +
                    '<a class="waves-effect manitouResetFiltersButton manitouLink">' +
                        messages.buttons.resetFilters +
                    '</a>' +
                '</div>' :
                '') +
        '</div>';
    return html;
};

privateTemplates.backToPostingsButton = function () {
    var html =
        '<div class="col s12">' +
            '<a class="waves-effect manitouBackToPostingsButton manitouLink">' +
                messages.buttons.backToPostings +
            '</a>' +
        '</div>';
    return html;
};

privateTemplates.attachmentField = function (label, documentTypeId, isRequired, idFile, idFileName, classes) {
    var html =
        '<div class="file-field input-field col s12' + (classes ? ' ' + classes : '') + '">' +
            '<div class="btn">' +
                '<span id="label_' + idFileName + '">' + messages.buttons.file + '</span>' +
                '<input aria-labelledby="label_' + idFileName + '"' + (idFile ? 'id="' + idFile + '" ' : '') + 'type="file" ' +
                    (documentTypeId ? 'data-attachment-type="' + documentTypeId + '" ' : '') +
                    (isRequired ? 'required' : '') + '>' +
            '</div>' +
            '<div class="file-path-wrapper">' +
                '<input ' + (idFileName ? 'id="' + idFileName + '" ' : '') + 'class="file-path validate" type="text" ' +
                    'placeholder="' + label + (isRequired ? ' *' : '') + '" maxlength="128" ' + (isRequired ? 'required' : '') + '>' +
                '<label style="display:none;"' + (idFileName ? 'for="' + idFileName + '"' : '') + '>' + idFileName + '</label>' +
                '<span class="helper-text" data-error="' + messages.validations.attachmentError + '"></span>' +
            '</div>' +
        '</div>';
    return html;
}

privateTemplates.commentField = function (isRequired) {
    var elementType = (manitou.config.applicationCommentIsSingleLine ? 'input' : 'textarea');
    var classes = (manitou.config.applicationCommentIsSingleLine ? '' : 'materialize-textarea-no-auto-resize');
    var attributes = (manitou.config.applicationCommentIsSingleLine ? ' type="text"' : '');
    var maxLength = manitou.config.applicationCommentMaxLength || 2000;

    var html =
        '<div class="row manitouFormRow">' +
            '<div class="input-field col s12">' +
                '<' + elementType + ' id="manitouComment" class="validate ' + classes + '" maxlength="' + maxLength + '"' + attributes +
                    (isRequired ? ' required': '') + '>' +
                '</' + elementType + '>' +
                '<label for="manitouComment">' +
                    (messages.locale && manitou.config.applicationCommentLabel && manitou.config.applicationCommentLabel[messages.locale] ?
                        manitou.config.applicationCommentLabel[messages.locale] :
                        messages.labels.comment) +
                    (isRequired ? ' *' : '') +
                '</label>' +
                '<span class="helper-text" data-error="' + messages.validations.requiredFieldError + '"></span>' +
            '</div>' +
        '</div>';
    return html;
}

privateTemplates.customSelectField = function (label, isRequired, multipleSelections, otherManualOption, options, selectId, columnWidth, isConcatenatedInComment, index) {
    var optionsHtml = '';
    // Add an empty default value for single selection dropdown
    if (multipleSelections !== true) {
        optionsHtml += '<option value="" selected></option>'
    }

    var optionIdx = 0;
    options.forEach(
        function(option) {
            optionsHtml += '<option data-idx="' + optionIdx + '" value="' + option.value + '"' + (option.tooltip ? ' data-position="right" data-tooltip="' + option.tooltip + '"' : '')  + '>' + option.label + '</option>';
            optionIdx += 1;
        }
    );
    // Add an Other option with a field for a custom manual answer
    if (otherManualOption) {
        optionsHtml += '<option value="-1">' + messages.defaultOptions.other + '</option>';
    }

    var html =
        '<div class="input-field manitouSelectContainer col s' + (columnWidth ? columnWidth : 12) + '">' +
            '<label for="' + (selectId ? selectId : '') + '">' +
                label + (isRequired ? ' *' : '') +
            '</label>' +
            '<select data-idx="' + index + '" class="' + (isConcatenatedInComment ? 'manitouSelectToComment' : 'manitouSelectToColumn') +
                (otherManualOption ? ' manitouSelectWithOtherOption' : '') +
                '" id="' + (selectId ? selectId : '') + '" data-label="' + label + '"' +
                (isRequired ? ' required' : '') + (multipleSelections ? ' multiple' : '') + '>' +
                optionsHtml +
            '</select>' +
            '<span class="helper-text" data-error="' + messages.validations.requiredFieldError + '"></span>' +
        '</div>';

    if (otherManualOption) {
        html +=
            '<div class="input-field col s12 manitouOtherField" hidden>' +
                '<label for="' + (selectId ? selectId + '-otherField' : '') + '">' +
                    messages.labels.other + ' (' + label + ')' + ' *' +
                '</label>' +
                '<input id="' + (selectId ? selectId + '-otherField' : '') + '" type="text" class="validate" ' +
                    'maxlength="200" required>' +
                '<span class="helper-text" data-error="' + messages.validations.requiredFieldError + '"></span>' +
            '</div>';
    }

    return html;
}
