export {
    parseCV
}

function parseCV(fileRef) {
    var fileType = getFileExtension(fileRef);

    if (manitou.config.enableAIBasedCVParsing && fileRef && fileType && (manitou.config.cvParsingSupportedExtensions.indexOf(fileType) != -1)) {
        try {
            var fileReader = new FileReader();
            fileReader.onload = function (event) {
                var base64file = fileReader.result.replace(/[^,]*base64,/, '');

                var headers = {
                    'x-api-key': manitou.config.cvParsingAPIKey,
                    'Content-Type': 'application/json'
                };

                var payload = {
                    base64file: base64file,
                    filetype: fileType,
                };

                $.ajax({
                    url: manitou.config.cvParsingServiceUrl,
                    method: 'POST',
                    headers: headers,
                    data: JSON.stringify(payload),
                    success: function (response) {
                        if (response && response.infos) {
                            setInfoFields(response.infos);
                        }
                    },
                    error: function (error) {
                        console.log('cvParser: An ajax error occured while calling the cv parsing service');
                    }
                });
            }
            fileReader.readAsDataURL(fileRef);
        } catch (e) {
            console.log('cvParser: An error occured while trying to parse the CV');
        }
    }
}

function setInfoFields(candidateInfos) {
    function updateFieldIfEmpty(fieldId, value) {
        var element = document.getElementById(fieldId);
        if (!element.value && value) {
            element.value = value;
            var event;
            if (typeof (Event) === 'function') {
                event = new Event('change', { 'bubbles': true });
            } else {
                // Polyfill for IE
                event = document.createEvent('Event');
                event.initEvent('change', true, true);
            }
            element.dispatchEvent(event);
        }
    }

    if (candidateInfos) {
        // Update the application form with the provided infos if the user has not already typed in a field
        updateFieldIfEmpty('manitouFirstName', candidateInfos.f_name);
        updateFieldIfEmpty('manitouLastName', candidateInfos.l_name);
        updateFieldIfEmpty('manitouEmail', candidateInfos.email);
        updateFieldIfEmpty('manitouPhone', candidateInfos.phone);

        // Force the visual update of the Materialize input fields
        M.updateTextFields();
    }
}


function getFileExtension(file) {
    var fileType = '';
    try {
        fileType = file.name.replace(/.*\.(?=[^\.]*$)/, '');
    } catch (e) { }
    return fileType;
}
