export default {
    locale: 'en',
    errors: {
        genericTryAgain:
            "Please try again later or contact the site administrator.",
        getPostings:
            "An error occured while trying to retrieve the list of job postings.",
        getPostingDetails:
            "An error occured while trying to retrieve the job posting. The service might be " +
            "unavailable or the posting might no longer exists.",
        getPostingsFilters:
            "An error occured while trying to retrieve the postings filters.",
        getSelfServiceUrl:
            "An error occured while trying to retrieve the self-service URL.",
        getValidationParameters:
            "An error occured while trying to retrieve the validation data of the platform.",
        applyToPosting: "An error occured while trying to apply.",
        addAttachment:
            "An error occured while trying to add an attachment file.",
        addExtraAttachment:
            'An error occured while trying to send the file "{{fileName}}". ' +
            "Your application has still been successfully saved."
    },
    tableHeaders: {
        title: "Title",
        institution: "Category",
        location: "Location",
        deadline: "Deadline",
        commitment: "Commitment type",
        sector: "Job sector",
        jobField: "Job field",
        jobClass: "Job class",
        competitionNumber: "Competition no."
    },
    labels: {
        firstName: "First name",
        lastName: "Last name",
        email: "Email",
        phone: "Phone number",
        password: "Password",
        passwordConfirmation: "Password confirmation",
        resume: "Upload my resume",
        comment: "Comment",
        other: "Other",
        institution: "Category",
        jobSector: "Job sector",
        jobField: "Job field",
        jobClass: "Job class",
        commitment: "Commitment type",
        location: "Work location",
        spontaneousApplicationEntry: "Spontaneous application",
        politiqueConfidentialite: "I declare that I have read and accept <a class=\"manitouNormalLink\" href=\"${policyURL}\" target=\"_blank\">Manitou's privacy policy and the solution's terms of use</a>. *",
        infoLettre: "I want to receive email newsletters.",
        approbationSMS: "I agree to be contacted by text message.",
        declarationAge: "I declare that I am over 14 years old. *"
    },
    buttons: {
        sendApplication: "Apply",
        apply: "Apply",
        file: "File",
        filterResults: "Filter results",
        applyFilters: "Apply filters",
        resetFilters: "Reset filters",
        backToPostings: "Back to the postings list",
        facebookTooltip: "Share on Facebook",
        twitterTooltip: "Share on Twitter",
        linkedinTooltip: "Share on LinkedIn",
        mailTooltip: "Share by email",
        previousTooltip: "Previous page",
        nextTooltip: "Next page",
        selfService: "Access to the self-service",
        iTryMyLuck: "I try my luck!"
    },
    validations: {
        emailTooltip:
            "A valid email address must include an '@' and end with a valid doamin " +
            "(exemple@exemple.com).",
        emailError: "Please enter a valid email address (exemple@exemple.com).",
        emailCorrect: "This is a valid email address!",
        firstNameError: "The first name is required.",
        lastNameError: "The last name is required",
        attachmentError: "This file is required.",
        requiredFieldError: "This field is required.",
        attachmentMaxSizeError:
            "The file must be smaller than {{maxsize}} bytes.",
        attachmentEmptyError: "The file content cannot be empty.",
        attachmentNameEmptyError: "The file name cannot be empty.",
        privacyPolicyError: "You must agree to the privacy Policy and terms of Service to apply.",
        declarationAgeError: "You must declare that you are over 14 years old to apply."
    },
    titles: {
        postingsListTitle: "Currently available positions",
        applicationForm: "Apply to this posting",
        spontaneousApplication: "Spontaneous application"
    },
    text: {
        selfService:
            "A self-service is available to allow you to submit your resume, to complete your profile, to place a " +
            "spontaneous application, to receive alerts when jobs matching your profile are available and even more!.",
        spontaneousApplication:
            "Didn't find the job you were looking for? Send us your application!"
    },
    defaultOptions: {
        institution: "- All institutions -",
        jobSector: "- All sectors -",
        jobField: "- All job fields -",
        jobClass: "- All job classes -",
        commitment: "- All commitment types -",
        location: "- All work locations -",
        other: "Other"
    },
    pagination: {
        countResults: "Result(s)",
        countTo: "to",
        countOf: "of"
    },
    email: {
        shareSubject: "Job posting"
    },
    noDataFound: {
        noPostings: "No job postings are currently available to display.",
        noSearchResults: "No job postings currently match these filters."
    },
    services: {
        platform: "Job platform"
    }
};
